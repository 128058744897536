<template>
  <div class="pt-59 md:pt-0">
    <div
      class="relative isolate rounded-30 bg-fafafc px-70 py-60 shadow-xl shadow-black/5 md:rounded-none md:bg-transparent md:px-0 md:py-0 md:shadow-none"
    >
      <div class="absolute inset-x-0 -top-32 -z-10 mx-40 h-30 rounded-t-30 bg-fafafc opacity-80 md:hidden">
        <div class="absolute inset-x-0 -top-27 -z-10 mx-90 h-25 rounded-t-30 bg-fafafc opacity-80"></div>
      </div>

      <slot />
    </div>
  </div>
</template>

<script setup lang="ts"></script>
